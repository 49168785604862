@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Poppins:wght@500;600;800&display=swap');

@layer base {
    html{
        font-family: 'Poppins';
    }
}
